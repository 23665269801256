import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const ListItem: AppFC<HTMLAttributes<HTMLDivElement>> = ({
  children, className, ...props
}): AppElement => (
  <div {...props} className={`w-full flex max-md:flex-col items-center py-4 border-b border-listItem/40 px-5 ${className || ''}`}>
    {children}
  </div>
);
