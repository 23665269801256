import {
  CancelToken,
  Method,
} from 'axios';

import {
  CryptoCurrencyTypes,
  DisputeStatusTypes,
  NetworkTypes,
  OfferStatusTypes,
  OfferTypes, RatingGradeTypes,
  RequestTypes, TradeStatusTypes,
} from '../../../constants';

export type TokenDataType = {
  accessToken?: string,
  refreshToken?: string,
  adminAccessToken?: string,
  adminRefreshToken?: string,
};

export enum HttpRequestHeaderTypes {
  // Json = 'JSON',
  MultiPart = 'MultiPart',
}

export type RequestHeaderProps = {
  headerType?: HttpRequestHeaderTypes,
  withoutToken?: boolean,
};

export type CustomHeaderProps = {
  Accept?: string,
  Authorization?: string,
  type?: string,
  'apy-key'?: string,
  'Content-Type'?: string,
};

export type HttpRequestProps<D, P> = {
  url: string,
  data?: D,
  params?: P,
  method: RequestTypes | Method,
  withoutToken?: boolean,
  cancelToken?: CancelToken,
  signal?: AbortSignal,
  headerType?: HttpRequestHeaderTypes,
};

// export interface ResponseInterface {
//   status: number,
//   action?: RequestStatusTypes,
//   data: any,
//   message: string,
// }

export type GetDefaultParamsType =
    | { limit?: string }
    | { format?: string }
    | { id?: string }
    | null;

export type OfferParamsType = {
  OfferStatus?: OfferStatusTypes,
  UserId?: string,
  OfferType?: OfferTypes,
  CryptoCurrencyType?: CryptoCurrencyTypes,
  CurrencyCodeType?: string,
  Country?: string,
  PaymentMethodTypes?: string[],
  OrderBy?: string,
  Page?: number,
} | null;

export type TradeParamsType = {
  TradeStatus?: TradeStatusTypes,
  OfferId?: string,
  Page?: number,
} | null;

export type HTTPRequestParamsType = GetDefaultParamsType & OfferParamsType;

/** auth requests data * */

export type SignUpDataProps = {
  userName: string,
  email: string,
  password: string,
};

export type VerifyEmailConfirmDataProps = {
  email: string,
  token: string,
};

export type SignInDataProps = {
  userName?: string,
  email?: string,
  password: string,
};

export type SignOutDataProps = {
  accessToken: string,
  refreshToken: string,
};

export type ChangePasswordDataProps = {
  oldPassword: string,
  newPassword: string,
};

export type SendEmailDataProps = {
  email: string,
};

export type ResetPasswordConfirmDataProps = {
  email: string,
  token: string,
  password: string,
};

export type ProfileUpdateDataProps = {
  userName: string,
  email:string,
  fullName?: {
    firstName:string
    lastName:string
  },
  country: string,
};

/** offer requests data * */

export type OffersUpdateDataProps = {
  id?: string,
  offerStatus?: OfferStatusTypes,
  offerType: OfferTypes,
  country: string,
  offeredCurrency: CryptoCurrencyTypes,
  demandedCurrency: string,
  currencyReferenceMarket: string,
  tradeTimeMinutes: number,
  margin: number,
  minTrade?: number,
  maxTrade?: number,
  paymentMethodTypes: string[],
  description?: string,
};

/** wallet requests data * */

export type WalletCreateDataProps = {
  cryptoCurrencyType: CryptoCurrencyTypes,
  walletSecretType: NetworkTypes
};

export type WalletWithdrawDataProps = {
  address: string,
  amount: number
};

/** escrow requests data * */

export type TradeCreateDataProps = {
  offerId: string,
  offeredAmount: number,
  paymentMethodType: string,
  description?: string,
};

/** dispute requests data * */

export type DisputeCreateDataProps = {
  reason?: string,
};

export type DisputeResolveDataProps = {
  status: DisputeStatusTypes,
  resolution?: string
};

/** user rating requests data * */

export type UserRatingDataProps = {
  tradeId: string,
  ratingGradeType: RatingGradeTypes,
  comment?: string
};

export type HTTPRequestDataType =
    | TokenDataType
    | SignUpDataProps
    | VerifyEmailConfirmDataProps
    | SignInDataProps
    | SignOutDataProps
    | ChangePasswordDataProps
    | SendEmailDataProps
    | ResetPasswordConfirmDataProps
    | ProfileUpdateDataProps
    | OffersUpdateDataProps
    | WalletCreateDataProps
    | WalletWithdrawDataProps
    | TradeCreateDataProps
    | DisputeCreateDataProps
    | DisputeResolveDataProps
    | UserRatingDataProps
    | FormData;
