import React, { useContext, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { OfferActionTypes, OfferContext, ProfileContext } from '../../../../stores/contexts';

import { AppElement, AppFC } from '../../../../interfaces';

import { OfferBox } from '../../../Molecules';
import { OfferFilters } from '../../../Organisms';
import { OfferParamsType } from '../../../../services/httpRequest';
import { useCurrency, useOffer } from '../../../../stores/hooks';
import { Loader } from '../../../Atoms';
import { OfferStatusTypes, OfferTypes } from '../../../../constants';

import countries from '../../../../utils/countries.json';

export const Offers: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(true);

  const { profileState: { id: userId } } = useContext(ProfileContext);
  const { offerState: { publishedOffers, filters }, updateOfferState } = useContext(OfferContext);

  const { getOffers } = useOffer();
  const { getCryptocurrencyRate } = useCurrency();

  const { offerType } = filters;

  const currentOfferType = offerType === OfferTypes.Buy ? OfferTypes.Sell : OfferTypes.Buy;
  const currentOffers = publishedOffers?.filter((offer) => offer.userId !== userId) || [];

  useEffect(() => {
    const currentCurrency = countries.find(({ name, currency }) => currency.code + currency.name + name === filters.currencyCode)?.currency.code || '';
    console.log('currentCurrency', currentCurrency);
    const filterParams: OfferParamsType = {
      OfferType: filters.offerType,
      OfferStatus: OfferStatusTypes.Open,
      CryptoCurrencyType: filters.cryptoCurrency,
      CurrencyCodeType: currentCurrency,
      Country: filters.country,
      PaymentMethodTypes: filters.paymentMethodTypes,
    };

    Promise.all([
      getOffers(filterParams),
      getCryptocurrencyRate(currentCurrency, filters.cryptoCurrency),
    ]).then((response) => {
      updateOfferState({
        type: OfferActionTypes.UpdateCryptoCurrencyRates,
        payload: response[1],
      });

      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      // error handler
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Cryptolocally: Offers</title>
      </Helmet>
      {isLoading ? <Loader className="self-center justify-self-center" />
        : (
          <div className="flex max-md:flex-col self-center max-md:items-center gap-9 w-full py-20">
            <OfferFilters className="md:sticky md:top-10" />
            <div className="flex flex-col gap-10 w-full max-md:px-[3%]">
              <div>
                <h3>
                  {currentOfferType === OfferTypes.Sell ? 'Buying' : 'Selling'}
                  {' '}
                  Offers
                </h3>
                <p className="mt-2 leading-7">
                  {currentOfferType === OfferTypes.Sell ? 'Buy' : 'Sell'}
                  {' '}
                  with over 350 payment methods to choose from, including bank transfers, online wallets, and gift cards.
                </p>
              </div>
              <div className="flex flex-col gap-12">
                {currentOffers.map((offer, index) => (
                  <div key={index}><OfferBox offer={offer} /></div>))}
              </div>
            </div>
          </div>
        )}
    </>

  );
};
